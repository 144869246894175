const state = {
  clients: []
};

const mutations = {
  SET_CLIENTS(state: any, clients: any[]) {
    state.clients = clients;
  },
  ADD_CLIENT(state: any, client: object) {
    state.clients.push(client);
  },
  REPLACE_CLIENT(state: any, client: any) {
    const index = state.clients.findIndex((x: any) => x.id == client.id);
    state.clients[index] = client;
  }
};
const getters = {
  getClients: (state: { clients: any }) => state.clients
};
const actions = {
  setClients({ commit }: { commit: Function }, data: object) {
    commit("SET_CLIENTS", data);
  },
  addClient({ commit }: { commit: Function }, data: object) {
    commit("ADD_CLIENT", data);
  },
  replaceClient({ commit }: { commit: Function }, data: object) {
    commit("REPLACE_CLIENT", data);
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
