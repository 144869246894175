const state = {
  companies: [],
  trucks: [],
  trailers: []
};

const mutations = {
  SET_COMPANIES(state: any, companies: any[]) {
    state.companies = companies;
  },
  ADD_COMPANY(state: any, company: object) {
    state.companies.push(company);
  },
  SET_TRUCKS(state: any, trucks: any[]) {
    state.trucks = trucks;
  },
  ADD_TRUCK(state: any, truck: object) {
    state.trucks.push(truck);
  },
  REPLACE_TRUCK(state: any, truck: any) {
    const index = state.trucks.findIndex((x: any) => x.id == truck.id);
    state.trucks[index] = truck;
  },
  SET_TRAILERS(state: any, trailers: any[]) {
    state.trailers = trailers;
  },
  ADD_TRAILER(state: any, trailer: object) {
    state.trailers.push(trailer);
  },
  REPLACE_TRAILER(state: any, trailer: any) {
    const index = state.trailers.findIndex((x: any) => x.id == trailer.id);
    state.trailers[index] = trailer;
  }
};
const getters = {
  getCompanies: (state: { companies: any[] }) => state.companies,
  getTrucks: (state: { trucks: any[] }) => state.trucks,
  getTrailers: (state: { trailers: any[] }) => state.trailers
};

const actions = {
  setCompanies({ commit }: { commit: Function }, data: object) {
    commit("SET_COMPANIES", data);
  },
  addCompany({ commit }: { commit: Function }, data: object) {
    commit("ADD_COMPANY", data);
  },
  setTrucks({ commit }: { commit: Function }, data: object) {
    commit("SET_TRUCKS", data);
  },
  addTruck({ commit }: { commit: Function }, data: object) {
    commit("ADD_TRUCK", data);
  },
  replaceTruck({ commit }: { commit: Function }, data: object) {
    commit("REPLACE_TRUCK", data);
  },
  setTrailers({ commit }: { commit: Function }, data: object) {
    commit("SET_TRAILERS", data);
  },
  addTrailer({ commit }: { commit: Function }, data: object) {
    commit("ADD_TRAILER", data);
  },
  replaceTrailer({ commit }: { commit: Function }, data: object) {
    commit("REPLACE_TRAILER", data);
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
