import Vue from "vue";
import dayjs from "dayjs";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import PortalVue from "portal-vue";
import Buefy from "buefy";

import App from "./App.vue";
import router from "./router";
import store from "./store";

library.add(fas);
Vue.component("fa-icon", FontAwesomeIcon);
import "@/assets/css/tailwind.css";
import http from "@/api/http";

Vue.config.productionTip = false;
Vue.use(PortalVue);
Vue.use(Buefy, {
  defaultIconComponent: "fa-icon",
  defaultIconPack: "fas",
  customIconPacks: {
    fas: {
      sizes: {
        default: "lg",
        "is-small": "1x",
        "is-medium": "2x",
        "is-large": "3x"
      },
      iconPrefix: ""
    }
  }
});
Vue.filter("percentage", function(value: number, decimals: number) {
  if (!value) {
    value = 0;
  }
  if (!decimals) {
    decimals = 0;
  }
  value = value * 100;
  value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  return value.toFixed(decimals) + "%";
});

Vue.filter("number", function(value: number, minimum: number, maximum: number) {
  return Number(value).toLocaleString("en-US", {
    minimumFractionDigits: minimum,
    maximumFractionDigits: maximum
  });
});

Vue.prototype.$date = dayjs;

Vue.filter("currency", function(value: number) {
  if (typeof value !== "number") {
    return value;
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0
  });
  return formatter.format(value);
});

const accessToken = localStorage.getItem("tk");
if (accessToken) {
  http.defaults.headers.common["Authorization"] = accessToken;
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
