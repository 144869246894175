const state = {
  lots: [],
  lotsHistorial: []
};

const mutations = {
  SET_LOTS(state: any, lots: any[]) {
    state.lots = lots;
  },
  SET_LOTS_HISTORIAL(state: any, lots: any[]) {
    state.lotsHistorial = lots;
  },
  ADD_LOT(state: any, lot: object) {
    state.lots.push(lot);
  },
  SET_LOT_TRADE(state: any, lot: any) {
    const index = state.lots.findIndex((x: any) => x.id == lot.id);
    state.lots[index].trade_id = lot.trade_id;
    state.lots[index].lot_id = lot.lot_id;
  },
  SET_LOT_HISTORIAL_TRADE(state: any, lot: any) {
    const index = state.lotsHistorial.findIndex((x: any) => x.id == lot.id);
    state.lotsHistorial[index].trade_id = lot.trade_id;
    state.lotsHistorial[index].lot_id = lot.lot_id;
  },
  SET_LOT_ITEM_DATA_SELECTED(state: any, data: any) {
    const index = state.lots.findIndex((x: any) => x.id == data.lotId);
    state.lots[index].items_data_selected = data.selected;
  },
  DELETE_LOT(state: any, lotId: number) {
    const index = state.lots.findIndex((x: any) => x.id == lotId);
    state.lots.splice(index, 1);
  },
  ADD_LOTS(state: any, lots: any[]) {
    state.lots.push(...lots);
  }
};
const getters = {
  getLots: (state: { lots: any[] }) => state.lots,
  getLotsHistorial: (state: { lotsHistorial: any[] }) => state.lotsHistorial
};

const actions = {
  setLots({ commit }: { commit: Function }, data: object) {
    commit("SET_LOTS", data);
  },
  setLotsHistorial({ commit }: { commit: Function }, data: object) {
    commit("SET_LOTS_HISTORIAL", data);
  },
  addLot({ commit }: { commit: Function }, data: object) {
    commit("ADD_LOT", data);
  },
  setLotTrade({ commit }: { commit: Function }, data: object) {
    commit("SET_LOT_TRADE", data);
  },
  setLotTradeHistorial({ commit }: { commit: Function }, data: object) {
    commit("SET_LOT_HISTORIAL_TRADE", data);
  },
  setSelectedItemData({ commit }: { commit: Function }, data: any) {
    commit("SET_LOT_ITEM_DATA_SELECTED", data);
  },
  deleteLot({ commit }: { commit: Function }, lotId: number) {
    commit("DELETE_LOT", lotId);
  },
  addLots({ commit }: { commit: Function }, lots: any[]) {
    commit("ADD_LOTS", lots);
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
