const state = {
  loads: []
};

const mutations = {
  SET_LOADS(state: any, loads: any[]) {
    state.loads = loads;
  },
  ADD_LOAD(state: any, load: object) {
    state.loads.push(load);
  },
  REPLACE_LOAD(state: any, load: any) {
    const index = state.loads.findIndex((x: any) => x.id == load.id);
    state.loads[index] = load;
  },
  DELETE_LOAD(state: any, loadId: number) {
    const index = state.loads.findIndex((x: any) => x.id == loadId);
    state.loads.splice(index, 1);
  }
};
const getters = {
  getLoads: (state: { loads: any[] }) => state.loads
};

const actions = {
  setLoads({ commit }: { commit: Function }, data: object) {
    commit("SET_LOADS", data);
  },
  addLoad({ commit }: { commit: Function }, data: object) {
    commit("ADD_LOAD", data);
  },
  replaceLoad({ commit }: { commit: Function }, data: object) {
    commit("REPLACE_LOAD", data);
  },
  deleteLoad({ commit }: { commit: Function }, loadId: number) {
    commit("DELETE_LOAD", loadId);
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
