import http from "@/api/http";

class UserService {
  create(data: any) {
    return http.post("/user", data);
  }
  login(data: any) {
    return http.post("/auth", data);
  }
  get() {
    return http.get("/user");
  }
}

export default new UserService();
