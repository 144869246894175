import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "@/store";
import UserService from "@/api/user";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/login",
    name: "LoginRedirect",
    meta: {
      requiresAuth: false
    },
    component: () => import("../views/Login.vue")
  },
  {
    path: "/login/:email?",
    name: "Login",
    meta: {
      requiresAuth: false
    },
    component: () => import("../views/Login.vue")
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      requiresAuth: false
    },
    component: () => import("../views/Register.vue")
  },
  {
    path: "/admin",
    name: "layout",
    redirect: "admin/home",
    meta: {
      requiresAuth: true
    },
    component: () => import("../views/Layout.vue"),
    children: [
      {
        path: "home",
        name: "Home",
        component: () => import("../views/Home.vue")
      },
      {
        path: "nueces",
        name: "Nueces",
        component: () => import("../views/nueces/Index.vue"),
        children: [
          {
            path: "detalle/:id",
            name: "DetalleNueces",
            component: () => import("../views/nueces/Detalle.vue")
          },
          {
            path: ":idNuez/nueva-muestra",
            name: "NuevaMuestra",
            component: () => import("../views/nueces/Detalle.vue")
          }
        ]
      },
      {
        path: "por-pagar",
        name: "PorPagar",
        component: () => import("../views/tesoreria/PorPagar.vue")
      },
      {
        path: "pagadas",
        name: "Pagadas",
        component: () => import("../views/tesoreria/Pagadas.vue")
      },
      {
        path: "bodegas",
        name: "Bodegas",
        component: () => import("../views/bodegas/Index.vue")
      },
      {
        path: "productores",
        name: "Productores",
        component: () => import("../views/productores/Index.vue")
      },
      {
        path: "camiones",
        name: "CamionesCatalog",
        component: () => import("../views/catalogs/Camiones.vue")
      },
      {
        path: "lotes",
        name: "Lotes",
        component: () => import("../views/lotes/Index.vue"),
        children: [
          {
            path: "agregar",
            name: "AgregarLote",
            component: () => import("../views/lotes/Agregar.vue")
          },
          {
            path: "editar/:lotId",
            name: "EditarLote",
            component: () => import("../views/lotes/Agregar.vue")
          }
        ]
      },
      {
        path: "cargas",
        name: "Cargas",
        component: () => import("../views/cargas/Index.vue"),
        children: [
          {
            path: "agregar",
            name: "AgregarCarga",
            component: () => import("../views/cargas/Agregar.vue")
          },
          {
            path: "editar/:loadId",
            name: "EditarCarga",
            component: () => import("../views/cargas/Agregar.vue")
          }
        ]
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth == true)) {
    if (localStorage.getItem("tk") == null) {
      next({
        name: "Login",
        params: { nextUrl: to.fullPath }
      });
    }
    store.dispatch("setSidebarStatus", false);
    if (store.getters["UserModule/getMe"] == null) {
      UserService.get().then(response => {
        store.dispatch("UserModule/setMe", response.data);
        next();
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresAuth == false)) {
    if (localStorage.getItem("tk") != null) {
      next({
        name: "Home",
        params: { nextUrl: to.fullPath }
      });
    }
    next();
  }
  next();
});

export default router;
