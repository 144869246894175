const state = {
  me: null,
  currentStorage: null
};

const mutations = {
  SET_ME(state: any, data: object) {
    state.me = data;
  },
  CURRENT_STORAGE(state: any, data: object) {
    state.currentStorage = data;
  }
};
const getters = {
  getMe: (state: { me: any }) => state.me,
  getStorage: (state: { currentStorage: any }) => {
    const almacen = localStorage.getItem("almacen");
    if (almacen) {
      return JSON.parse(almacen);
    } else if (state.currentStorage) {
      return state.currentStorage;
    }
    return undefined;
  }
};
const actions = {
  setMe({ commit }: { commit: Function }, data: object) {
    commit("SET_ME", data);
  },
  setStorage({ commit }: { commit: Function }, data: object) {
    localStorage.setItem("almacen", JSON.stringify(data));
    commit("CURRENT_STORAGE", data);
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
