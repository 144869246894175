const state = {
  items: [],
  activeItem: undefined,
  dashboard: undefined
};

const mutations = {
  SET_ITEMS(state: any, items: any[]) {
    state.items = items;
  },
  ADD_ITEM(state: any, item: object) {
    state.items.push(item);
  },
  SET_ITEM(state: any, item: any) {
    const index = state.items.findIndex((x: any) => x.id == item.id);
    state.items[index] = item;
  },
  SET_ACTIVE_ITEM(state: any, item: any) {
    state.activeItem = item;
  },
  SET_DASHBOARD(state: any, data: any) {
    state.dashboard = data;
  }
};
const getters = {
  getItems: (state: { items: any[] }) => state.items,
  getDashboard: (state: { dashboard: any }) => state.dashboard
};

const actions = {
  setItems({ commit }: { commit: Function }, data: object) {
    commit("SET_ITEMS", data);
  },
  addItem({ commit }: { commit: Function }, data: object) {
    commit("ADD_ITEM", data);
  },
  setItem({ commit }: { commit: Function }, data: object) {
    commit("SET_ITEM", data);
  },
  setActiveItem({ commit }: { commit: Function }, data: object) {
    commit("SET_ACTIVE_ITEM", data);
  },
  setDashboard({ commit }: { commit: Function }, data: object) {
    commit("SET_DASHBOARD", data);
  }
};

export default {
  namespaced: true,
  state: {
    ...state
  },
  getters: {
    ...getters
  },
  mutations,
  actions
};
