import axios from "axios";
import router from "@/router/index";
const http = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    "Content-type": "application/json"
  }
});

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (
      error.response.data.msg &&
      error.response.data.msg == "Invalid auth token provided"
    ) {
      localStorage.removeItem("tk");
      router.go(0);
    }
    throw error;
  }
);
export default http;
