import Vue from "vue";
import Vuex from "vuex";
import UserModule from "@/store/modules/UserModule";
import ClientModule from "@/store/modules/ClientModule";
import ItemModule from "@/store/modules/ItemModule";
import LotModule from "@/store/modules/LotModule";
import TransportModule from "@/store/modules/TransportModule";
import LoadModule from "@/store/modules/LoadModule";

Vue.use(Vuex);

const state = {
  sidebarOpen: false,
  modalNuecesOpen: false,
  modalProductorOpen: false,
  modalCamionOpen: false
};

export default new Vuex.Store({
  state,
  mutations: {
    SET_SIDEBAR(state, status: boolean) {
      state.sidebarOpen = status;
    },
    SET_MODAL_NUECES(state, status: boolean) {
      state.modalNuecesOpen = status;
    },
    SET_MODAL_PRODUCTOR(state, status: boolean) {
      state.modalProductorOpen = status;
    },
    SET_MODAL_CAMION(state, status: boolean) {
      state.modalCamionOpen = status;
    }
  },
  actions: {
    setSidebarStatus({ commit }, status: boolean) {
      commit("SET_SIDEBAR", status);
    },
    setModalNuecesStatus({ commit }, status: boolean) {
      commit("SET_MODAL_NUECES", status);
    },
    setModalProductorStatus({ commit }, status: boolean) {
      commit("SET_MODAL_PRODUCTOR", status);
    },
    setModalCamionStatus({ commit }, status: boolean) {
      commit("SET_MODAL_CAMION", status);
    }
  },
  modules: {
    UserModule,
    ClientModule,
    ItemModule,
    LotModule,
    TransportModule,
    LoadModule
  }
});
